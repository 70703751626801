@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Karla:ital,wght@0,400;0,700;1,400;1,700&display=swap);
body {
  margin: 0;
  font-family: "Inter", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.react-datepicker-wrapper {
  width: 100%;
}
body .react-datepicker {
  font-family: "Inter", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  border-color: #63b3ed;
}
body .react-datepicker__header {
  background-color: #f4f6f9;
  border-color: #63b3ed;
}
body .react-datepicker-popper {
  z-index: 5;
}
body
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: #63b3ed;
}
body
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
body
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-width: 10px;
}
body
  .react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before {
  border-top-color: #63b3ed;
}
body .react-datepicker__navigation-icon {
  top: 9px;
}
body .react-datepicker__navigation-icon::before {
  border-color: #000;
}
body .react-datepicker__day-name,
body .react-datepicker__day,
body .react-datepicker__time-name {
  width: 2rem;
  line-height: 2rem;
}
body .react-datepicker__current-month {
  padding-top: 5px;
  padding-bottom: 5px;
}
body .react-datepicker__day--today {
  background-color: #f4f6f9;
  border-radius: 5px;
}
body .react-datepicker-popper .react-datepicker__triangle {
  left: 50% !important;
  -webkit-transform: translateX(-50%) !important;
          transform: translateX(-50%) !important;
}

